import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function DataProcessingAgreement() {
  return (
    <div className="container">
        <div className="fnt-16">
            <h1 className="my-5 py-5 text-center fw-bold">Data Processing Agreement</h1>
            <div className="mb-4">This Data Processing Agreement ("DPA") is incorporated into and is subject to the terms and conditions of, the Agreement between Meet Dripfunnel and the customer entity that is a party to the Agreement ("Customer" or "you").</div>
            <div className="mb-4">All capitalized terms not defined in this DPA shall have the meanings set forth in the Agreement.
                   In this agreement, the following defined terms apply:</div>
            <div className="mb-4"><span  className="fw-bold">“Agreement”</span> means this data processing agreement and its schedules.</div>
            <div className="mb-4"><span  className="fw-bold">“Controller”</span> has the meaning given to it in: (a) the GDPR, if the GDPR is applicable; or (b) the UK DPA, if the UK DPA is applicable.</div>
            <div className="mb-4"><span  className="fw-bold">“Data Protection Authority”</span> means: (a) if the GDPR is applicable, a “Supervisory Authority”, as that term is defined in the GDPR; or (b) if the UK DPA is applicable, the Information Commissioner.</div>
            <div className="mb-4"><span  className="fw-bold">“Data Protection Impact Assessment”</span> means a data protection impact assessment, as described in Article 35 of the GDPR.</div>
            <div className="mb-4"><span  className="fw-bold">“Data Protection Laws”</span> means: (a) the UK DPA; (b) the GDPR, Directive 95/46/EC, Directive 2002/58/EC and Directive 2009/136/EC, together with any national implementing laws in any Member State of the European Union; and (c) any equivalent legislation, or legislation dealing with the same subject matter, anywhere in the world, in each case as amended, consolidated or replaced from time to time.</div>
            <div className="mb-4"><span  className="fw-bold">“Data Subject”</span> has the meaning given to it in: (a) the GDPR, if the GDPR is applicable; or (b) the UK DPA, if the UK DPA is applicable.</div>
            <div className="mb-4"><span  className="fw-bold">“GDPR”</span> means Regulation (EU) 2016/679, as amended, consolidated or replaced from time to time.</div>
            <div className="mb-4"><span  className="fw-bold">“Personal Data”</span> has the meaning given to it in: (a) the GDPR, if the GDPR is applicable; or (b) the UK DPA, if the UK DPA is applicable.</div>
            <div className="mb-4"><span  className="fw-bold">“Personal Data Breach”</span> has the meaning given to it in: (a) the GDPR, if the GDPR is applicable; or (b) the UK DPA, if the UK DPA is applicable.</div>
            <div className="mb-4">‍<span  className="fw-bold">“Personnel” </span>means any current, former or prospective employee, consultant, temporary worker, agency worker, intern, other non-permanent employee, contractor, secondee, or other personnel</div>
            <div className="mb-4"><span  className="fw-bold">“Process”, “Processing” or “Processed”</span> each have the meanings given to them in the GDPR.</div>
            <div className="mb-4"><span className="fw-bold">“Processor” </span>has the meaning given to it in the GDPR.</div>
            <div className="mb-4"><span  className="fw-bold">“Relevant Personal Data”</span> means the categories of Personal Data that are set out in Schedule 1 and that are Processed under, or in connection with the provision of the Services.</div>
            <div className="mb-4"><span  className="fw-bold">“Services”</span> means the Meet Dripfunnel web services, as more particularly described in the Services Agreement.  </div>
            <div className="mb-4"><span  className="fw-bold">“Services Agreement”</span> means the terms of service agreement entered into by Parties on or around the date of this Agreement.</div>
            <div className="mb-4"><span  className="fw-bold">“Subprocessor”</span> means any party (including but not limited to affiliates and sub-contractors) engaged by Service Provider to Process Relevant Personal Data.</div>
            <div className="mb-4"><span  className="fw-bold">“Term”</span> has the meaning given to it in Clause 2 below.</div>
            <div className="mb-4"><span className="fw-bold">“UK DPA”</span> means the Data Protection Act 2018, as amended, consolidated or replaced from time to time.</div>
            <div className="mb-4 fw-bold">1. Obligations of Service Provider</div>
            <div className="mb-4">1.1 With respect to the Processing of Relevant Personal Data, Service Provider shall, and shall procure that each of its Personnel, agents and Subprocessors shall comply with all Data Protection Laws, to the extent applicable.</div>
            <div className="mb-4">1.2 Service Provider represents and warrants to Customer that it shall implement appropriate technical and organisational measures to protect Relevant Personal Data, in accordance with applicable Data Protection Laws and, during the GDPR Period, in accordance with Articles 32-34 of the GDPR in particular. Service Provider shall ensure that such technical and organisational measures are appropriate to the particular risks that are presented by its Processing activities, in particular to protect Relevant Personal Data from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access. Prior to the Processing of any Relevant Personal Data, and then regularly thereafter, Service Provider shall document its relevant technical and organisational security measures (3). Service Provider shall perform internal inspections on a regular basis, to confirm that it is complying with its obligations under this Agreement and, where appropriate, Service Provider shall amend its Processing activities to satisfy its obligations under this Agreement.</div>
            <div className="mb-4">1.3 The Parties hereby acknowledge and agree that Customer is a Controller and Service Provider is a Processor with respect to the Processing of Relevant Personal Data. In addition to, and notwithstanding, any other right or obligation arising under this Agreement or the Services Agreement, the Service Provider shall, in relation to such Processing:</div>
            <div className="mb-4">(a) comply with the express instructions or directions of Customer given from time to time in connection with the Processing of Relevant Personal Data, and the requirements of any applicable Data Protection Laws; and</div>
            <div className="mb-4">(b) only Process Relevant Personal Data strictly and solely: (i) to the extent necessary in connection with this Agreement, in particular as described in Schedule 1 below; and (ii) in accordance with the documented instructions received from Customer from time to time. If at any point, Service Provider becomes legally unable to comply with Customer's instructions regarding the Processing of Relevant Personal Data (whether as a result of a change in applicable law, or a change in Customer's instructions), Service Provider shall promptly:</div>
            <div className="mb-4">(i) notify Customer of such inability, providing a reasonable level of detail as to the instructions with which it cannot comply and the reasons why it cannot comply, to the greatest extent permitted by applicable law; and</div>
            <div className="mb-4">(ii) cease all Processing of the affected Relevant Personal Data (other than merely storing and maintaining the security of the affected Relevant Personal Data) until such time as Customer issues new instructions with which Service Provider is able to comply;</div>
            <div className="mb-4">(c) (i) create; (ii) keep up-to-date for the duration of the Processing; and (iii) maintain for four (4) years thereafter; complete and accurate records in writing (including in electronic form) of its Processing activities, including all categories of its Processing activities, in relation to Relevant Personal Data, and disclose such records to Customer, or any Data Protection Authority, promptly upon demand;</div>
            <div className="mb-4">(d) ensure Relevant Personal Data are kept confidential; (ii) take all reasonable steps to ensure the reliability and trustworthiness of Service Provider’s Personnel and any Subprocessors, and (iii) ensure that all relevant Service Provider Personnel, and any relevant Subprocessors, have committed themselves to ensuring the confidentiality of all Relevant Personal Data that they Process;</div>
            <div className="mb-4">(e) ensure that, in each instance in which it engages a Subprocessor to Process any Relevant Personal Data, it shall: (i) only appoint such Subprocessor in accordance with the prior written authorisation of Customer (such authorisation not to be unreasonably withheld, conditioned or delayed); (ii) keep Customer informed if there is any change to the role or status of the Subprocessor; and (iii) enter into a binding written agreement with the Subprocessor that imposes on the Subprocessor the same obligations that apply to Service Provider under this Agreement with respect to the Processing of Relevant Personal Data and, if applicable, any restricted transfers of Relevant Personal Data;</div>
            <div className="mb-4">(f) at Customer’s request and expense, promptly provide Customer with all reasonable technical and organisational assistance necessary to respond appropriately to requests from Data Subjects to exercise their rights;</div>
            <div className="mb-4">(g) at Customer’s request and expense, promptly provide Customer with all reasonable assistance necessary to enable Customer to: (i) notify relevant breaches of the GDPR to the relevant Data Protection Authorities and/or affected Data Subjects; (ii) conduct Data Protection Impact Assessments; and (iii) obtain any necessary authorisations from Data Protection Authorities;</div>
            <div className="mb-4">(h) permanently and securely delete (or, at the election of Customer, return) all Relevant Personal Data in the possession or control of Service Provider or any of its Subprocessors, within thirty (30) days after the end of the Term, unless the applicable law of England or the European Union or an EU Member State (as applicable) requires otherwise; and (ii) procure that its Subprocessors shall do likewise;</div>
            <div className="mb-4">(i) at Customer’s request and expense: (i) promptly provide Customer with all information necessary to enable Customer to demonstrate compliance with its obligations under the GDPR, to the extent that Service Provider is able to provide such information; and (ii) allow for and contribute to audits, including inspections, conducted by Customer or an auditor appointed by Customer; and</div>
            <div className="mb-4">(j) notify Customer promptly, and in any event within twenty-four (24) hours, of: (i) becoming aware of any Personal Data Breach affecting Relevant Personal Data; (ii) becoming aware of any material breach of this Clause 1; or (iii) receipt of any correspondence or communication from any Data Subject, Data Protection Authority or third party regarding the Processing of Relevant Personal Data.</div>
            <div className="mb-4">1.4 Service Provider shall not, whether through action or omission, place Customer in breach of any applicable Data Protection Laws.</div>
            <div className="mb-4">1.5 Service Provider shall remain primarily liable and responsible for the acts and omissions of its Subprocessors.</div>
            <div className="mb-4">‍1.6 Service Provider complies with the relevant articles in the GDPR in regards to the personal data being processed on behalf of the Controller.</div>

            <div className="mb-4 fw-bold">2. General</div>
            <div className="mb-4">2.1 This Agreement shall commence on the date that this Agreement is fully executed and shall automatically terminate on the date of termination or expiration of the Services Agreement.</div>
            <div className="mb-4">2.2 This Agreement may be executed in two or more counterparts, each of which shall constitute an original, and all of which together shall constitute the same agreement.</div>
            <div className="mb-4">‍2.3 This Agreement, and any disputes arising from or relating to the interpretation thereof (including non-contractual disputes), shall be governed by English law and shall be subject to the exclusive jurisdiction of the English Courts.</div>
            <div className="mb-4 fw-bold">3. Security</div>
            <div className="mb-4">3.1 All of our services run in the cloud. We don’t host or run our own routers, load balancers, DNS servers, or physical servers.</div>
            <div className="mb-4">‍3.2 Our service is built on Amazon Web Services (AWS). They provide strong security measures to protect our infrastructure and are compliant with most certifications.</div>
            <div className="mb-4">‍3.3 Our applications pseudonymize data to ensure the privacy of data subjects. Any attributes that don’t need to remain in their original form are truncated to remove any possibility of being linked to a specific data subject.</div>
            <div className="mb-4">3.4 For more information please visit our Security & Compliance Policy.</div>
            <div className="mb-4 fw-bold">Schedule 1– Data Processing Activities</div>
            <div className="mb-4">Service Provider’s fulfilment of its obligations under the Services Agreement will involve the following Processing activities:</div>
            <div>
                <ul>
                    <li  className="mb-4">provision of the Services;</li>
                    <li className="mb-4">improvements and updates to Services.</li>
                </ul>
            </div>
            <div  className="mb-4 fw-bold">Duration of the Processing</div>
            <div  className="mb-4">The Processing of Relevant Personal Data shall continue for the duration of the Term of this Agreement, plus any additional periods explicitly permitted or required under: (i) this Agreement; or (ii) applicable laws. </div>
            <div  className="mb-4 fw-bold">Data subjects</div>
            <div  className="mb-4">‍The Relevant Personal Data concern the following categories of Data Subjects:</div>
            <div>
                <ul>
                    <li  className="mb-4">Personnel of Customer and its affiliates and service providers;</li>
                    <li  className="mb-4 ">prospective and current clients of Customer’s business products; </li>
                    <li  className="mb-4 ">consumer users of Customer’s website or mobile apps or other services.</li>
                </ul>
            </div>
            <div className="mb-4 fw-bold">Categories of Relevant Personal Data</div>
            <div className="mb-4">The following Relevant Personal Data may be Processed by Service Provider:</div>
            <div>
                <ul>
                    <li className="mb-4">contact details (email, name and profile picture);</li>
                    <li className="mb-4">time zone; </li>
                    <li className="mb-4">login credentials (e.g. LinkedIn, Gmail);</li>
                    <li className="mb-4">IP address;</li>
                    <li className="mb-4">browser, device and operating system being used;</li>
                    <li className="mb-4">metadata, headers and account settings.</li>
                </ul>
            </div>
            <div className="mb-4 fw-bold">Special categories of data</div>
            <div className="mb-4">It is not anticipated that any categories of Sensitive Personal Data will be Processed by Service Provider. </div>
            <div className="mb-4 fw-bold">Data Processing operations</div>
            <div className="mb-4 ">The Purposes for which the Relevant Personal Data are Processed are as follows: </div>
            <div>
                <ul>
                    <li className="mb-4">provision of the Services i.e. providing the web services platform that automates certain networking tasks on LinkedIn and Gmail;</li>
                    <li className="mb-4">communicating with the Customer in relation to the Services;</li>
                    <li className="mb-4">if agreed between the Parties as applicable, aggregating anonymized statistical data regarding the Services; and</li>
                    <li className="mb-4">complying with applicable laws or legal obligations.</li>
                </ul>
            </div>
            <div className="mb-4 fw-bold">Contact Information</div>
            <div className="mb-5">If you have questions or complaints regarding this Policy, please first contact us at <a className="accent-color" href="https://www.dripfunnel.com/">dripfunnel.com</a>.</div>
        </div>
    </div>
  );
}

export default DataProcessingAgreement;
